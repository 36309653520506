<template>

    <svg id="icons" xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 100 100">

        <path 
            v-if="socialName == 'facebook'"
            id="icon_facebook"
            d="M57.81,100V56.25H76.56L79.69,37.5H57.81V28.12q0-9.37,9.38-9.37h9.37V0H60.94C45.31,0,39.06,9.37,39.06,25V37.5H20.31V56.25H39.06V100Z"
            />

        <path
            v-else-if="socialName == 'twitter'"
            id="icon_twitter"
            d="M31.45,90.63c37.73,0,58.37-31.27,58.37-58.38,0-.89,0-1.77-.06-2.65A41.53,41.53,0,0,0,100,19a40.91,40.91,0,0,1-11.78,3.23,20.67,20.67,0,0,0,9-11.35,41.29,41.29,0,0,1-13,5,20.54,20.54,0,0,0-35,18.71A58.25,58.25,0,0,1,7,13.12,20.62,20.62,0,0,0,13.31,40.5,20.28,20.28,0,0,1,4,37.94v.26A20.59,20.59,0,0,0,20.46,58.31a20.55,20.55,0,0,1-9.26.35A20.58,20.58,0,0,0,30.36,72.91a41.15,41.15,0,0,1-25.48,8.8A41.92,41.92,0,0,1,0,81.41a58,58,0,0,0,31.45,9.2"
            />
        
        <g 
            v-else-if="socialName == 'linkedin'"
            id="icon_linkedin">
            <rect x="0.5" y="31.39" width="22.14" height="68.25"/>
            <path d="M82.94,32.19l-.7-.23-.9-.17a19.51,19.51,0,0,0-4-.4c-12.91,0-21.1,9.41-23.79,13v-13H31.44V99.63H53.58V62.41S70.31,39,77.37,56.2V99.63H99.5v-46A22.13,22.13,0,0,0,82.94,32.19Z"/>
            <path d="M22.16,11.22A10.83,10.83,0,1,1,11.33.37,10.84,10.84,0,0,1,22.16,11.22Z"/>
        </g>

        <path 
            v-else-if="socialName == 'github'"
            id="icon_github"
            d="M50,1A50.23,50.23,0,0,0,34.2,98.87c2.5.46,3.41-1.09,3.41-2.42s0-4.35-.07-8.53C23.63,91,20.7,81.19,20.7,81.19c-2.28-5.8-5.55-7.34-5.55-7.34-4.54-3.12.34-3.06.34-3.06,5,.36,7.66,5.18,7.66,5.18,4.46,7.67,11.7,5.45,14.55,4.17a10.79,10.79,0,0,1,3.17-6.72c-11.1-1.26-22.77-5.57-22.77-24.8a19.44,19.44,0,0,1,5.15-13.47c-.52-1.27-2.23-6.38.48-13.29,0,0,4.2-1.35,13.75,5.15a47.22,47.22,0,0,1,25,0c9.55-6.5,13.74-5.15,13.74-5.15,2.72,6.91,1,12,.49,13.29a19.39,19.39,0,0,1,5.14,13.47c0,19.28-11.69,23.53-22.82,24.77,1.79,1.55,3.39,4.61,3.39,9.29,0,6.71-.06,12.13-.06,13.77,0,1.35.9,2.91,3.43,2.42A50.23,50.23,0,0,0,50,1" 
            />

        <g 
            v-else-if="socialName == 'codepen'"
            id="icon_codepen">
            <path d="M50,1h0a14.14,14.14,0,0,1,7.8,2.34L95.21,28.27A8.54,8.54,0,0,1,99,35.34V64.57a8.56,8.56,0,0,1-3.79,7.08L57.8,96.57A14.09,14.09,0,0,1,50,99h0a14.14,14.14,0,0,1-7.8-2.34l-37.41-25A8.55,8.55,0,0,1,1,64.61V35.39a8.55,8.55,0,0,1,3.79-7.08L42.2,3.39A14.08,14.08,0,0,1,50,1Z" />
            <polygon points="43.61 13.28 43.61 34.59 30.83 43.11 13.78 32.46 43.61 13.28"/>
            <polygon points="56.39 13.28 56.39 34.59 69.17 43.11 86.22 32.46 56.39 13.28"/>
            <polygon points="56.39 85.72 56.39 64.41 69.17 55.89 86.22 66.54 56.39 85.72"/>
            <polygon points="43.61 85.72 43.61 64.41 30.83 55.89 13.78 66.54 43.61 85.72"/>
            <polygon points="9.52 43.11 20.17 49.5 9.52 55.89 9.52 43.11"/>
            <polygon points="90.48 55.89 79.83 49.5 90.48 43.11 90.48 55.89"/>
            <polygon points="50 40.98 37.22 49.5 50 58.02 62.78 49.5 50 40.98"/>
        </g>

        <path 
            v-else-if="socialName == 'email'"
            id="icon_email"
            d="M99.91,81.35a1.91,1.91,0,0,0,.09-.54V19.18a1.9,1.9,0,0,0-.09-.53.19.19,0,0,1,0-.07,1.69,1.69,0,0,0-.33-.53s0,0,0,0h0a1.79,1.79,0,0,0-.52-.38l0,0a1.65,1.65,0,0,0-.59-.14H1.63a1.7,1.7,0,0,0-.6.14l0,0A1.74,1.74,0,0,0,.47,18h0l0,0a1.87,1.87,0,0,0-.33.54.19.19,0,0,1,0,.07,1.91,1.91,0,0,0-.09.54V80.82a1.62,1.62,0,0,0,.1.54.25.25,0,0,0,0,.07A1.51,1.51,0,0,0,.44,82l0,0,0,0a1.54,1.54,0,0,0,.19.17l.08.07.25.13s0,0,.06,0a1.72,1.72,0,0,0,.68.14H98.26a1.72,1.72,0,0,0,.68-.14l.1,0a1.13,1.13,0,0,0,.21-.12l.1-.08c.05,0,.11-.09.16-.15l0,0s0,0,0,0a1.51,1.51,0,0,0,.32-.53ZM3.48,23.13,32.77,49.88l-29.29,27Zm60,25.44a1.77,1.77,0,0,0-.35.26s0,.09-.09.14L50,60.91l-43.77-40H93.78ZM35.35,52.24,48.83,64.55a1.72,1.72,0,0,0,2.34,0L64.48,52.39,93.77,79.07H6.2ZM67.07,50l29.45-26.9V76.87Z" 
        />

          <g
            v-else-if="socialName == 'pluralsight'" 
            id="icon_pluralsight"
            fill="#820333">
            <path class="cls-1" d="M65.22,8.18a44.5,44.5,0,1,0,26.6,57,44.55,44.55,0,0,0-26.6-57M50,99A49,49,0,1,1,99,50,49.05,49.05,0,0,1,50,99" />
            <path class="cls-1" d="M34.78,21.74V78.26L82.61,50Zm4.35,8.7L73.91,50,39.13,69.57Z"/>
            <path class="cls-1" d="M26.09,28.26V71.74L60.87,50Zm4.34,10.87L52.17,50,30.43,60.87Z"/>
        </g>

    </svg>

</template>

<script>
    export default {
        name: 'Social_icons',

        data() {
            return {
                socialName: this.nameOfSocial
            }
        },
        
        props: {
            nameOfSocial: {
                type: String,
            }
        },
    }
</script>